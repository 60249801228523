import { buildTimeConfig } from 'config'
import Head from 'next/head'
import { PropsWithChildren } from 'react'
import { Link } from '../Link/Link'
import { ResponsiveImage } from '../ResponsiveImage/ResponsiveImage'

export const LayoutWithLogo = ({ children }: PropsWithChildren) => {
  return (
    <div className="flex min-h-screen flex-col bg-gray-50">
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      <header className="px-6">
        <Link href="/" className="flex py-10">
          <ResponsiveImage
            src="/assets/svg/logo.svg"
            alt={`${buildTimeConfig.internalBankName}-logo`}
            className="h-6 w-auto"
          />
        </Link>
      </header>
      <div className="flex flex-1 flex-col">{children}</div>
    </div>
  )
}
