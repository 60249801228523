import { Link } from 'modules/common/components/Link/Link'
import { Seo } from 'modules/common/components/Seo/Seo'
import { LayoutWithLogo } from 'modules/common/components/_layouts/LayoutWithLogo'
import { useCountryExternalLinks } from 'modules/common/hooks/useCountryExternalLinks'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { BlankPage } from 'modules/common/types/page'
import { GetStaticProps } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'

const NotFoundPage: BlankPage = () => {
  const { t } = useTranslation('common-page-404')
  const externalLinks = useCountryExternalLinks()

  return (
    <div className="flex flex-1 flex-col items-center justify-around text-center">
      <Seo title={t('meta.title')} noindex />
      <div>
        <h1 className="pb-6 text-4xl font-bold">{t('h1')}</h1>
      </div>
      <Link
        href={`mailto:${externalLinks.supportEmail}`}
        className="text-primary"
      >
        {t('needHelp')}
      </Link>
    </div>
  )
}

export const getStaticProps: GetStaticProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale as string, [
      'common-page-404',
      'common',
      'common-components',
    ])),
  },
})

NotFoundPage.getLayout = (page) => <LayoutWithLogo>{page}</LayoutWithLogo>
NotFoundPage.type = 'detached'

export default NotFoundPage
